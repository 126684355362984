import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { ellipse, fileTray, receipt, square, triangle } from 'ionicons/icons'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import TransactionTab from '@/pages/TransactionTab'
import AccountTab from '@/pages/AccountTab'
import SettingTab from '@/pages/SettingTab'
import Example from '@/pages/example'
import { UpdateTransaction } from '@/pages/transaction/UpdateTransaction'
import { JumpPage } from '@/pages/JumpPage'

setupIonicReact()

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          {/* Muse use Redirect with exact, otherwise we got a blank page (Only have tabs) when first time open the website. */}
          {/* iOS WPA didn't provide a way to refresh page, so the entire app keeps blank forever. */}
          <Redirect exact path="/" to="/transaction" />
          {/* Pages cannot be pushed in the tab if using a tab that not showing in the first place. */}
          {/* So using a new route to have two TransactionTab is not feasible */}
          {/*<Route exact path="/" component={TransactionTab} />*/}

          <Route exact path="/transaction" component={TransactionTab} />
          <Route exact path="/transactions/:id" component={UpdateTransaction} />

          <Route exact path="/account" component={AccountTab} />
          <Route exact path="/account/:id" component={JumpPage} />
          <Route exact path="/accountPicker" component={Example} />

          <Route exact path="/setting" component={SettingTab} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/transaction">
            <IonIcon aria-hidden="true" icon={receipt} />
            <IonLabel>Transaction</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/account">
            <IonIcon aria-hidden="true" icon={fileTray} />
            <IonLabel>Account</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/setting">
            <IonIcon aria-hidden="true" icon={square} />
            <IonLabel>Setting</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
)

export default App
