import { getDB } from '@/components/database/Database'
import React, {
  createContext,
  createRef,
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonNav,
  IonNavLink,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react'
import { IonNavContext } from '@/utils/IonNavContext'
import {
  Posting,
  Transaction,
  TransactionContext,
  FocusedItemIndexContext,
} from '@/components/transaction/types'
import { nanoid } from 'nanoid'
import { PostingItem } from '@/components/transaction/PostingItem'
import { TransactionDocument } from '@/components/database/schemas/Transaction'
import { chevronUpCircle } from 'ionicons/icons'
import moment from 'moment'

const CreateTransaction = ({
  onDismiss,
  initTransaction,
}: {
  onDismiss: () => void
  initTransaction?: () => Transaction
}) => {
  const nav = useRef<HTMLIonNavElement>(null)

  const [transaction, setTransaction] = useState<Transaction | null>(
    initTransaction
      ? initTransaction()
      : new Transaction([
          {
            metadata: new Map<string, string>(),
            accountName: 'TBD',
            commodity: 'USD',
            comment: '',
          },
          {
            metadata: new Map<string, string>(),
            accountName: 'TBD',
            commodity: 'USD',
            comment: '',
          },
        ])
  )

  // Prevent creating new component on every render
  const createPageContentFn = useCallback(
    () => (
      <>
        <Page title={'Create Transaction'} onDismiss={onDismiss} />
      </>
    ),
    []
  )

  return (
    <IonNavContext.Provider value={nav}>
      <TransactionContext.Provider value={[transaction, setTransaction]}>
        <IonNav ref={nav} root={createPageContentFn}></IonNav>
      </TransactionContext.Provider>
    </IonNavContext.Provider>
  )
}

export default CreateTransaction

export const Page = ({
  title,
  onDismiss,
}: {
  title: string
  onDismiss: () => void
}) => {
  const [transaction, setTransaction] = useContext(TransactionContext)
  const [present, dismiss] = useIonLoading()
  const [focusedItemIndex, setFocusedItemIndex] = useState<number | null>(null)

  const createTransaction = async () => {
    if (transaction === null) {
      return
    }

    const db = await getDB()
    const transactionData = {
      id: transaction.id,
      time: transaction.unixTime,
      data: JSON.stringify(transaction),
    } as TransactionDocument

    await db.transactions.upsert(transactionData)
    await dismiss()

    onDismiss()
  }

  if (transaction === null) {
    return <div>Loading...</div>
  }

  return (
    <>
      <FocusedItemIndexContext.Provider
        value={[focusedItemIndex, setFocusedItemIndex]}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>Cancel</IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                onClick={async () => {
                  await present({
                    message: 'Loading...',
                  })
                  await createTransaction()
                }}
              >
                Done
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          <IonList>
            {transaction.postings.map((posting, index) => {
              return (
                <PostingItem
                  index={index}
                  key={index.toString()}
                  posting={posting}
                  onChange={(val) => {
                    setTransaction({
                      ...transaction,
                      postings: transaction.postings.map((p, i) => {
                        if (i === index) {
                          return val
                        }
                        return p
                      }),
                    } as Transaction)
                  }}
                  onDelete={() => {
                    setTransaction({
                      ...transaction,
                      postings: transaction.postings.filter(
                        (_, i) => i !== index
                      ),
                    } as Transaction)
                  }}
                />
              )
            })}
          </IonList>
          <IonButton
            onClick={() => {
              setTransaction({
                ...transaction,
                postings: [
                  ...transaction.postings,
                  {
                    metadata: new Map<string, string>(),
                    accountName: 'TBD',
                    commodity: 'USD',
                    comment: '',
                  },
                ],
              } as Transaction)
            }}
          >
            Add Posting
          </IonButton>
          <IonList>
            <IonItem>
              {/*<IonDatetime presentation="date-time"></IonDatetime>{' '}*/}
              <IonDatetimeButton datetime="datetime"></IonDatetimeButton>

              <IonModal keepContentsMounted={true}>
                <IonDatetime
                  id="datetime"
                  value={moment
                    .unix(transaction.unixTime)
                    .format('YYYY-MM-DDTHH:mm:ssZ')}
                  onIonChange={(e) => {
                    if (typeof e.target.value === 'string') {
                      setTransaction({
                        ...transaction,
                        unixTime: new Date(e.target.value).getTime() / 1000,
                      } as Transaction)
                    }
                  }}
                ></IonDatetime>
              </IonModal>
            </IonItem>
            <IonItem>
              <IonInput
                key={'payee'}
                labelPlacement="floating"
                label="Payee"
                value={transaction.payee}
                onIonInput={(e) => {
                  if (typeof e.target.value === 'string') {
                    setTransaction({
                      ...transaction,
                      payee: e.target.value,
                    } as Transaction)
                  }
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Narration"
                value={transaction.narration}
                onIonInput={(e) => {
                  if (typeof e.target.value === 'string') {
                    setTransaction({
                      ...transaction,
                      narration: e.target.value,
                    } as Transaction)
                  }
                }}
              ></IonInput>
            </IonItem>
          </IonList>

          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton
              onClick={() => {
                if (focusedItemIndex !== null) {
                  setTransaction({
                    ...transaction,
                    postings: transaction.postings.map((p, i) => {
                      if (i === focusedItemIndex) {
                        if (p.amount !== undefined) {
                          p.amount *= -1
                        }
                      }
                      return p
                    }),
                  } as Transaction)
                }
              }}
            >
              +/-
            </IonFabButton>
          </IonFab>
        </IonContent>
      </FocusedItemIndexContext.Provider>
    </>
  )
}
