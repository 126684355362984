import {
  CheckboxCustomEvent,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import React, {
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AccountDocument } from '@/components/database/schemas/Account'
import { getDB } from '@/components/database/Database'
import { IonNavContext } from '@/utils/IonNavContext'

export const SelectAccounts = ({
  selectedItem,
  onSelectionChange,
}: {
  selectedItem: string
  onSelectionChange?: (val: string) => void
}) => {
  const navContext = useContext(IonNavContext)

  const [accounts, setAccounts] = useState<AccountDocument[]>([])
  useEffect(() => {
    getDB().then((db) => {
      db.accounts
        .find({
          // selector: {
          //   category: category,
          // },
          sort: [{ name: 'asc' }],
        })
        .$.subscribe((accounts) => {
          setAccounts(accounts)
        })
    })
  }, [])

  const [searchText, setSearchText] = useState<string>('')
  const [filteredAccounts, setFilteredAccounts] = useState<AccountDocument[]>(
    []
  )
  useEffect(() => {
    if (accounts.length === 0) {
      setFilteredAccounts(accounts)
      return
    }
    const filtered = accounts.filter((account) => {
      return searchText.split(' ').every((searchWord) => {
        return account.name.toLowerCase().includes(searchWord.toLowerCase())
      })
    })
    setFilteredAccounts(filtered)
  }, [accounts, searchText])

  const [selectedValue, setSelectedValue] = useState<string>(selectedItem)
  const confirmChanges = () => {
    if (onSelectionChange !== undefined) {
      onSelectionChange(selectedValue)
    }
    navContext?.current?.pop()
  }

  // Check list
  const isChecked = (value: string) => {
    return selectedValue === value
  }
  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value } = ev.detail

    if (checked) {
      setSelectedValue(value)
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={confirmChanges}>Done</IonButton>
          </IonButtons>

          <IonTitle>Select Account</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            onIonInput={(ev) => {
              if (ev.target.value !== undefined && ev.target.value !== null) {
                setSearchText(ev.target.value)
              }
            }}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList inset={true}>
          {filteredAccounts.map((item) => (
            <IonItem key={item.name}>
              <IonCheckbox
                value={item.name}
                checked={isChecked(item.name)}
                onIonChange={checkboxChange}
              >
                {item.name}
              </IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  )
}
