import React, { createContext, RefObject } from 'react'
import { nanoid } from 'nanoid'
import moment from 'moment'

export class Transaction {
  id: string
  unixTime: number
  payee?: string
  narration?: string
  postings: Posting[]

  constructor(postings: Posting[] = []) {
    this.id = nanoid()
    this.unixTime = Date.now() / 1000
    this.postings = postings
  }
}

export class Template {
  name: string
  transaction: Transaction

  constructor(name: string, transaction: Transaction) {
    this.name = name
    this.transaction = transaction
  }
}

export const transactionToBeancount = (t: Transaction): string => {
  let result = `${moment.unix(t.unixTime).format('YYYY-MM-DD')} * "${
    t.payee ? t.payee : ''
  }" "${t.narration ? t.narration : ''}"\n`
  result += `    timestamp: ${Math.trunc(t.unixTime)}\n`
  if (t.postings.length !== 0) {
    for (let i = 0; i < t.postings.length; i++) {
      const posting = t.postings[i]
      result += `    ${posting.accountName}`
      if (posting.amount) {
        result += `    ${posting.amount} ${posting.commodity}`
      }
      if (posting.comment) {
        result += `  ; ${posting.comment}`
      }
      result += '\n'
    }
  }
  return result
}

export const transactionToBeancountElement = (t: Transaction) => {
  const title = `${moment.unix(t.unixTime).format('YYYY-MM-DD')} * "${
    t.payee ? t.payee : ''
  }" "${t.narration ? t.narration : ''}"\n`

  const body = []
  if (t.postings.length !== 0) {
    for (let i = 0; i < t.postings.length; i++) {
      let result = ''
      const posting = t.postings[i]
      if (posting.amount) {
        result += `    ${posting.amount} ${posting.commodity}`
      }
      if (posting.comment) {
        result += `  ; ${posting.comment}`
      }
      body.push(
        <p key={body.length}>
          <b>{posting.accountName}</b> {result}
        </p>
      )
    }
  }
  return (
    <div>
      <h3>{title}</h3>
      {body}
    </div>
  )
}

export interface Posting {
  metadata: Map<string, string>
  accountName: string
  amount?: number
  commodity: string
  comment?: string
}

export const TransactionContext = createContext<
  [Transaction | null, React.Dispatch<React.SetStateAction<Transaction | null>>]
>([
  null,
  () => {
    console.log('setTransaction not implemented')
  },
])

export const FocusedItemIndexContext = createContext<
  [number | null, React.Dispatch<React.SetStateAction<number | null>>]
>([
  null,
  () => {
    console.log('FocusedItemIndexContext not implemented')
  },
])
