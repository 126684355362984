import { Transaction, TransactionContext } from '@/components/transaction/types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IonNavContext } from '@/utils/IonNavContext'
import { IonNav, IonPage } from '@ionic/react'
import { getDB } from '@/components/database/Database'
import { RouteComponentProps } from 'react-router'
import { TransactionDocument } from '@/components/database/schemas/Transaction'
import { Page } from '@/pages/transaction/CreateTransaction'

type UpdateTransactionProps = RouteComponentProps<{
  id: string
}>

export const UpdateTransaction = (props: UpdateTransactionProps) => {
  const nav = useRef<HTMLIonNavElement>(null)

  const [transaction, setTransaction] = useState<Transaction | null>(null)

  useEffect(() => {
    getDB().then(async (db) => {
      const t = await db.transactions
        .findOne({
          selector: { id: props.match.params.id },
          sort: [{ time: 'desc' }],
        })
        .exec()

      if (t) {
        const transaction = JSON.parse(t.data) as Transaction
        setTransaction(transaction)
      }
    })
  }, [])

  // Prevent creating new component on every render
  const createPageContentFn = useCallback(
    () => (
      <>
        <Page
          title={'Update Transaction'}
          onDismiss={() => {
            props.history.goBack()
          }}
        />
      </>
    ),
    []
  )

  return (
    <IonPage>
      <IonNavContext.Provider value={nav}>
        <TransactionContext.Provider value={[transaction, setTransaction]}>
          <IonNav ref={nav} root={createPageContentFn}></IonNav>
        </TransactionContext.Provider>
      </IonNavContext.Provider>
    </IonPage>
  )
}
