import { FocusedItemIndexContext, Posting } from '@/components/transaction/types'
import {
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonNavLink,
  IonRow,
  IonSelect,
  IonSelectOption
} from '@ionic/react'
import { SelectAccounts } from '@/pages/account/SelectAccounts'
import React, { useContext, useEffect, useState } from 'react'
import './PostingItem.css'


export const PostingItem = ({
                              index,
                              posting,
                              onChange,
                              onDelete
                            }: {
  index: number
  posting: Posting
  onChange: (val: Posting) => void
  onDelete: () => void
}) => {
  const [p, setP] = useState<Posting>(posting)
  useEffect(() => {
    if (p === posting) return
    onChange(p)
  }, [p])

  const [_, setFocusedItemIndex] = useContext(FocusedItemIndexContext)

  return (
    <IonItemSliding>
      <IonItem className={'ion-no-padding'}>
        <IonGrid>
          <IonRow class={'ion-align-items-center'}>
            <IonCol size='5'>
              <IonNavLink
                // force ion-input to not steal focus
                tabIndex={1}
                component={() => (
                  <SelectAccounts
                    selectedItem={''}
                    onSelectionChange={(val) => {
                      setP({
                        ...p,
                        accountName: val
                      })
                    }}
                  />
                )}
              >
                {p.accountName}
              </IonNavLink>
            </IonCol>
            <IonCol class={'ion-text-end'} size='4'>
              <IonInput
                key={'amount'}
                // force ion-input to not steal focus
                tabIndex={2}
                type='number'
                inputmode='decimal'
                placeholder={'0.00'}
                value={p.amount}
                onIonInput={(e) => {
                  if (typeof e.target.value === 'string' && e.target.value !== '') {
                    let val = parseFloat(e.target.value)

                    if (e.target.value === '-') {
                      val = -0
                    }
                    setP({
                      ...p,
                      amount: val
                    })
                  }
                }}
                onFocus={() => {
                  setFocusedItemIndex(index)
                }}
              ></IonInput>
            </IonCol>
            <IonCol size='3'>
              <IonSelect
                aria-label='currencry'
                justify='end'
                value={p.commodity}
                class={'bg-slate-50'}
              >
                <IonSelectOption value='USD'>USD</IonSelectOption>
                <IonSelectOption value='CNY'>CNY</IonSelectOption>
                <IonSelectOption value='bananas'>Bananas</IonSelectOption>
              </IonSelect>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>

      <IonItemOptions>
        <IonItemOption
          color='danger'
          onClick={() => {
            onDelete()
          }}
        >
          Delete
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  )
}
