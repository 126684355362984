import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxCollection,
  RxDocument,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb'

export const transactionLiteral = {
  title: 'transaction schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 100,
    },
    time: {
      type: 'number',
    },
    data: {
      type: 'string',
    },
  },
  required: ['id', 'time', 'data'],
  indexes: ['time'],
} as const

const schemaTyped = toTypedRxJsonSchema(transactionLiteral)

// aggregate the document type from the schema
export type TransactionDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>

// create the typed RxJsonSchema from the literal typed object.
export const transactionSchema: RxJsonSchema<TransactionDocType> =
  transactionLiteral

export type TransactionDocument = RxDocument<TransactionDocType>

// and then merge all our types
export type TransactionCollection = RxCollection<TransactionDocType>
