import {
  ActionSheetButton,
  IonActionSheet,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonNav,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import {
  chevronUpCircle,
  clipboardOutline,
  colorPalette,
  document,
  documents,
  documentText,
  globe,
  receipt,
} from 'ionicons/icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CreateTransaction from '@/pages/transaction/CreateTransaction'
import {
  Template,
  Transaction,
  transactionToBeancount,
  transactionToBeancountElement,
} from '@/components/transaction/types'
import { getDB } from '@/components/database/Database'
import { Clipboard } from '@capacitor/clipboard'

const TransactionTab = () => {
  const createEmptyTransactionModal = useRef<HTMLIonModalElement>(null)
  const createFromRecentTransactionModal = useRef<HTMLIonModalElement>(null)
  const createFromTemplateModal = useRef<HTMLIonModalElement>(null)

  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  )
  const [presentToast] = useIonToast()

  useEffect(() => {
    getDB().then((db) => {
      db.transactions
        .find({
          sort: [{ time: 'desc' }],
        })
        .$.subscribe((transactions) => {
          setTransactions(
            transactions.map((x) => {
              return JSON.parse(x.data) as Transaction
            })
          )
        })
      db.templates
        .find({
          sort: [{ time: 'desc' }],
        })
        .$.subscribe((templates) => {
          setTemplates(
            templates.map((x) => {
              return new Template(x.name, JSON.parse(x.data) as Transaction)
            })
          )
        })
    })
  }, [])

  const transactionItem = (transaction: Transaction) => {
    return (
      <IonItemSliding key={transaction.id}>
        <IonItem routerLink={'/transactions/' + transaction.id}>
          <IonLabel>{transactionToBeancountElement(transaction)}</IonLabel>
        </IonItem>

        <IonItemOptions>
          <IonItemOption
            color="danger"
            onClick={() => {
              getDB().then(async (db) => {
                await db.transactions
                  .find({ selector: { id: transaction.id } })
                  .remove()
              })
            }}
          >
            Delete
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Transactions</IonTitle>
          <IonButtons id="open-clear-transactions-sheet" slot="start">
            <IonButton>Clear</IonButton>
          </IonButtons>
          <IonActionSheet
            trigger="open-clear-transactions-sheet"
            header="Clear Transactions"
            buttons={[
              {
                text: 'Delete All',
                role: 'destructive',
                data: {
                  action: 'delete',
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
                data: {
                  action: 'cancel',
                },
              },
            ]}
            onWillDismiss={async (e) => {
              const db = await getDB()
              if (e.detail.data === undefined) {
                return
              }
              if (e.detail.data.action === 'delete') {
                await db.transactions.find().remove()
              }
            }}
          ></IonActionSheet>

          <IonButtons slot="end">
            <IonButton
              onClick={async () => {
                const exported = transactions
                  .reverse()
                  .map(transactionToBeancount)
                  .join('\n')

                await Clipboard.write({
                  string: exported,
                })

                await presentToast({
                  message: 'Exported to clipboard',
                  duration: 2000,
                  icon: clipboardOutline,
                })
              }}
            >
              Export
            </IonButton>
          </IonButtons>

          <IonActionSheet
            trigger="open-create-from-template-sheet"
            header="Select Template"
            buttons={templates
              .map((x) => {
                return {
                  text: x.name,
                  role: 'selected',
                  data: {
                    action: 'select',
                    template: x,
                  },
                } as ActionSheetButton
              })
              .concat([
                {
                  text: 'Cancel',
                  role: 'cancel',
                  data: {
                    action: 'cancel',
                  },
                },
              ])}
            onWillDismiss={async (e) => {
              if (e.detail.data === undefined) {
                return
              }
              if (e.detail.data.action === 'select') {
                setSelectedTemplate(e.detail.data.template)
                await createFromTemplateModal.current?.present()
              }
            }}
          ></IonActionSheet>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonModal
          ref={createEmptyTransactionModal}
          trigger="open-create-empty-transaction-modal"
        >
          <CreateTransaction
            onDismiss={() => {
              createEmptyTransactionModal.current?.dismiss()
            }}
          />
        </IonModal>
        <IonModal
          ref={createFromRecentTransactionModal}
          trigger="open-create-from-recent-transaction-modal"
        >
          <CreateTransaction
            onDismiss={() => {
              createFromRecentTransactionModal.current?.dismiss()
            }}
            initTransaction={() => {
              if (transactions.length === 0) {
                return new Transaction()
              }

              const recentTransaction = transactions[0]
              const t = new Transaction()
              recentTransaction.postings.forEach((posting) => {
                t.postings.push(posting)
              })

              return t
            }}
          />
        </IonModal>
        <IonModal ref={createFromTemplateModal}>
          <CreateTransaction
            onDismiss={() => {
              createFromTemplateModal.current?.dismiss()
            }}
            initTransaction={() => {
              if (selectedTemplate === null) {
                return new Transaction()
              }

              const templateTransaction = selectedTemplate.transaction
              const t = new Transaction()
              templateTransaction.postings.forEach((posting) => {
                t.postings.push(posting)
              })
              t.payee = templateTransaction.payee
              t.narration = templateTransaction.narration

              return t
            }}
          />
        </IonModal>

        <IonList>{transactions.map(transactionItem)}</IonList>

        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon icon={chevronUpCircle}></IonIcon>
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton id="open-create-empty-transaction-modal">
              <IonIcon icon={document}></IonIcon>
            </IonFabButton>
            <IonFabButton id="open-create-from-recent-transaction-modal">
              <IonIcon icon={documentText}></IonIcon>
            </IonFabButton>
            <IonFabButton>
              <IonIcon
                id="open-create-from-template-sheet"
                icon={documents}
              ></IonIcon>
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
    </IonPage>
  )
}

export default TransactionTab
