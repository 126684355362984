import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxCollection,
  RxDocument,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb'

export const templateLiteral = {
  title: 'template schema',
  version: 0,
  primaryKey: 'name',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      maxLength: 100,
    },
    time: {
      type: 'number',
    },
    data: {
      type: 'string',
    },
  },
  required: ['name', 'time', 'data'],
  indexes: ['time'],
} as const

const schemaTyped = toTypedRxJsonSchema(templateLiteral)

// aggregate the document type from the schema
export type TemplateDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof schemaTyped
>

// create the typed RxJsonSchema from the literal typed object.
export const templateSchema: RxJsonSchema<TemplateDocType> = templateLiteral

export type TemplateDocument = RxDocument<TemplateDocType>

// and then merge all our types
export type TemplateCollection = RxCollection<TemplateDocType>
